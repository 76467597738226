/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/roboto-v30-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v30-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v30-latin-700.woff2") format("woff2"), url("../fonts/roboto-v30-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/dosis-v26-latin-regular.woff2") format("woff2"), url("../fonts/dosis-v26-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* trispace-regular - latin */
@font-face {
  font-family: 'Trispace';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/trispace-v17-latin-regular.woff2") format("woff2"), url("../fonts/trispace-v17-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* trispace-700 - latin */
@font-face {
  font-family: 'Trispace';
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/trispace-v17-latin-700.woff2") format("woff2"), url("../fonts/trispace-v17-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

@media (max-width: 926px) and (min-width: 801px) {
  main #about .container div:last-child {
    margin-top: 2.5rem; } }

:root {
  --backdrop-filter: blur(1rem);
  --showcase-image: url('../img/Simon_Portrait_copyright_blaufuehlerfotografie_01_thumbnail.jpg'); }

:focus {
  outline: 3px dashed #ff974c; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  background-color: #e0e0e0; }

#js-notification {
  text-align: center;
  height: 70px;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 20px;
  border: 1px solid transparent;
  position: fixed;
  top: 0;
  z-index: 6;
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
  border-top-color: #a6e1ec;
  display: none; }

.wrapper {
  margin: 0 auto; }

html {
  scroll-behavior: smooth; }

a {
  text-decoration: none;
  transition: background 0.2s ease-in;
  color: #006666;
  cursor: pointer; }
  a:hover {
    color: #ff974c; }

a.dark {
  text-decoration: none;
  transition: background 0.2s ease-in;
  color: #33ffff;
  cursor: pointer; }
  a.dark:hover {
    color: #ff974c; }

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  display: inline !important;
  color: white !important; }

.btn-light, .btn-dark {
  padding: 1rem;
  display: inline-block; }

.btn-light {
  background-color: #e0e0e0;
  color: black; }

.btn-dark {
  background-color: #222;
  color: white; }

a[class^='btn-']:hover {
  background-color: #ff974c; }

.btn-submit {
  background-color: #222;
  color: white;
  width: 80px !important;
  padding: 0.5rem 0.1rem;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-out; }
  .btn-submit:hover {
    background-color: #ff974c; }

.full-width {
  width: 100%;
  box-sizing: border-box; }

/* Change mark color */
::selection {
  background: #ff974c; }

::-moz-selection {
  background: #ff974c; }

h1 {
  font-family: 'Dosis', sans-serif;
  text-align: center; }

h2 {
  font-family: 'Dosis', sans-serif;
  text-align: center;
  font-size: 32px; }

h4 {
  font-family: 'Trispace', sans-serif; }

#top-menu a.active {
  border-bottom: 3px solid #ff974c; }

input[type='checkbox'][id='toggler'],
header .navbar .logo .hamburger {
  display: none; }

.margin-1y {
  margin: 1rem 0; }

.margin-2y {
  margin: 2rem 0; }

.margin-3y {
  margin: 3rem 0; }

.margin-1x {
  margin: 0 1rem; }

.margin-2x {
  margin: 0 2rem; }

.margin-3x {
  margin: 0 3rem; }

.padding-05y {
  padding: 0.5rem 0; }

.padding-1y {
  padding: 1rem 0; }

.padding-2y {
  padding: 2rem 0; }

.padding-3y {
  padding: 3rem 0; }

.padding-1x {
  padding: 0 1rem; }

.padding-2x {
  padding: 0 2rem; }

.padding-3x {
  padding: 0 3rem; }

.padding-bottom-1 {
  padding-bottom: 1rem; }

.padding-bottom-3 {
  padding-bottom: 3rem; }

.padding-bottom-8 {
  padding-bottom: 8rem; }

.padding-top-05 {
  padding-top: 0.5rem; }

.padding-top-3 {
  padding-top: 3rem; }

.small-text-aligned-center {
  color: #006666;
  font-size: 0.8rem;
  text-align: center; }

.arrow {
  border: solid black;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 6px; }

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

header .navbar {
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: #fdf2e871;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  transition: all 0.2s ease-in; }
  header .navbar-change-background {
    background-color: black; }
  header .navbar .logo {
    flex-basis: 40px; }
  header .navbar .nav ul {
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap; }
    header .navbar .nav ul li {
      margin: 0 1rem; }
      header .navbar .nav ul li a {
        font-family: 'Dosis', sans-serif;
        font-weight: 700;
        font-size: 0.95rem;
        padding-bottom: 2px;
        color: #fff;
        transition: border-color 0.2s ease-in; }
        header .navbar .nav ul li a:hover {
          border-bottom: 3px solid #ff974c; }
        header .navbar .nav ul li a:active {
          border: none; }

header #showcase-container {
  position: relative;
  width: 100%;
  height: 100svh; }

header .showcase {
  width: 100%;
  height: 100%;
  background: var(--showcase-image) no-repeat 50%/cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #f4f4f4;
  text-align: center;
  z-index: 2;
  max-width: 1840px; }
  header .showcase::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    z-index: 2;
    backdrop-filter: var(--backdrop-filter);
    transition: backdrop-filter 0.2s ease-in; }
  header .showcase::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2; }

header .showcase-text .container {
  height: 44vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 3; }
  header .showcase-text .container .down-arrow {
    margin-bottom: 1rem;
    border-radius: 50%;
    color: #e0e0e0;
    transition: all 0.2s ease;
    z-index: 3; }
    header .showcase-text .container .down-arrow:hover {
      background-color: #ff974c !important; }
      header .showcase-text .container .down-arrow:hover .arrow.down {
        border: solid #fff;
        border-width: 0 6px 6px 0;
        display: inline-block;
        padding: 6px; }
  header .showcase-text .container div .typewriter {
    color: white;
    border-right: 2px solid orange;
    white-space: nowrap;
    overflow: hidden;
    animation: type 3s steps(40, end), cursor 1.25s infinite step-end;
    margin: 0 auto;
    max-width: 300px;
    letter-spacing: 0.1rem;
    font-style: italic;
    font-weight: 400; }

@keyframes cursor {
  from,
  to {
    border-color: transparent; }
  50% {
    border-color: orange; } }

@keyframes type {
  from {
    width: 0; }
  to {
    width: 300px; } }
  header .showcase-text .container div h1 {
    font-size: 3rem;
    font-weight: 300; }

main #about {
  background-color: #222;
  color: white; }
  main #about #flash {
    transition: all 0.5s 0.25s ease-in; }
  main #about .flash-light {
    color: red; }
  main #about .flash-light-standard {
    color: white; }
  main #about .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 170px;
    grid-gap: 1.8rem; }
    main #about .container div:first-child {
      grid-column: 1 / span 2;
      text-align: center;
      align-self: flex-end;
      font-family: 'Dosis', sans-serif;
      font-size: 1.5rem; }
    main #about .container div:nth-child(2) {
      max-width: 31.25rem;
      text-align: justify;
      justify-self: flex-end;
      align-self: flex-start; }
    main #about .container div:nth-child(3) {
      max-width: 31.25rem;
      align-self: flex-start;
      text-align: justify;
      justify-self: flex-start; }
    main #about .container div:last-child {
      grid-column: 1 / span 2;
      align-self: center;
      justify-self: center;
      max-width: 64.5rem;
      text-align: justify; }

main #projects {
  background-color: #e0e0e0;
  color: black; }
  main #projects h1 {
    font-size: 2rem; }
  main #projects .tech-logos {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: flex-start; }
    main #projects .tech-logos {
      transition: display 0.2s ease-in;
      justify-content: space-between; }
    main #projects .tech-logos .first {
      position: relative; }
      main #projects .tech-logos .first::after {
        content: "HTML";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem;
        left: -0.3rem; }
    main #projects .tech-logos .second {
      position: relative; }
      main #projects .tech-logos .second::after {
        content: "SCSS";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem;
        left: 0.4rem; }
    main #projects .tech-logos .third {
      position: relative; }
      main #projects .tech-logos .third::after {
        content: "JS/TS";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem; }
    main #projects .tech-logos .fourth {
      position: relative; }
      main #projects .tech-logos .fourth::after {
        content: "React";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem; }
    main #projects .tech-logos .fifth {
      position: relative; }
      main #projects .tech-logos .fifth::after {
        content: "Spring";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem; }
    main #projects .tech-logos .sixth {
      position: relative; }
      main #projects .tech-logos .sixth::after {
        content: "MySQL";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem;
        left: -0.2rem; }
    main #projects .tech-logos .seventh {
      position: relative; }
      main #projects .tech-logos .seventh::after {
        content: "PHP";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem;
        left: 0.6rem; }
    main #projects .tech-logos .eighth {
      position: relative; }
      main #projects .tech-logos .eighth::after {
        content: "Unity";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem;
        left: 0.3rem; }
    main #projects .tech-logos .nineth {
      position: relative; }
      main #projects .tech-logos .nineth::after {
        content: "Node";
        font-weight: bold;
        font-size: 0.7rem;
        color: #222;
        position: absolute;
        bottom: -0.9rem;
        left: 0.1rem;
        left: 0.1rem; }
  main #projects .outer-container {
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding: 0 1rem; }
    main #projects .outer-container .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; }
      main #projects .outer-container .container #project-1 {
        display: flex;
        flex-flow: column nowrap;
        align-items: center; }
        main #projects .outer-container .container #project-1 img {
          width: 80%; }
      main #projects .outer-container .container #project-3 {
        position: relative; }
      main #projects .outer-container .container #project-4 {
        position: relative; }
      main #projects .outer-container .container #project-5 {
        position: relative; }
      main #projects .outer-container .container #project-6 {
        position: relative; }
        main #projects .outer-container .container #project-6::after {
          content: 'new';
          position: absolute;
          top: 4px;
          right: 1px;
          color: #006666;
          font-size: 0.8rem;
          font-weight: bold; }
      main #projects .outer-container .container #project-7 {
        position: relative; }
      main #projects .outer-container .container .project {
        border: 1.5px dashed #222;
        padding: 1rem;
        background-color: white; }
        main #projects .outer-container .container .project h4 {
          text-align: center; }
        main #projects .outer-container .container .project p {
          text-align: justify; }

main #contact {
  background-color: #e0e0e0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center; }
  main #contact .contact-inner {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 60%; }
    main #contact .contact-inner .contact-link {
      display: flex;
      flex-flow: column nowrap;
      align-items: center; }
  main #contact .contact-inner > * {
    display: block;
    width: 50%;
    margin: 0.3rem 0; }

.footer-push {
  width: 100%;
  height: 140px;
  position: absolute;
  background: transparent;
  opacity: 1;
  z-index: -4; }

footer {
  width: 100svw;
  height: 140px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #222;
  z-index: -4;
  padding: 1rem;
  display: grid;
  place-items: center; }

input[type='email'],
input[type='text'],
textarea {
  height: 38px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box; }

input[type='email'],
input[type='text'],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px; }

input[type='email']:focus,
input[type='text']:focus,
textarea:focus {
  border: 1px solid #ff974c;
  outline: 0; }

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600; }

input[type='submit'] {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box; }

input[type='submit']:hover,
input[type='submit']:focus {
  color: #333;
  border-color: #888;
  outline: 0; }

input[type='submit'].button-primary {
  color: #fff;
  background-color: #ff974c;
  border-color: #ff974c; }

@media screen and (min-width: 1500px) {
  header #showcase-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    background: #222; }
  main #projects .outer-container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 1rem; }
    main #projects .outer-container .container {
      max-width: 1500px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 2rem; }
      main #projects .outer-container .container .project {
        grid-column: span 2; }
      main #projects .outer-container .container .project:nth-child(4) {
        grid-column: 1 / span 2; }
      main #projects .outer-container .container .project:nth-child(5) {
        grid-column: 3 / span 2; }
      main #projects .outer-container .container #project-1 img {
        width: 100%; } }

@media screen and (max-width: 800px) {
  input[type='checkbox'][id='toggler'],
  header .navbar .logo .hamburger {
    display: block; }
  #main-header .navbar {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 50px;
    background: #fdf2e871;
    transition: all 0.2s ease-in; }
    #main-header .navbar-change-background {
      background-color: black; }
    #main-header .navbar-fold-animation {
      background-color: black;
      height: 12.5rem; }
    #main-header .navbar .logo {
      width: 100%;
      margin: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between; }
      #main-header .navbar .logo .actual-logo {
        flex-basis: 40px;
        margin-left: 0.5rem; }
      #main-header .navbar .logo input[type='checkbox'][id='toggler'] {
        position: fixed;
        right: 0;
        width: 42px;
        height: 40px;
        opacity: 0;
        margin-right: 0.5rem;
        cursor: pointer;
        z-index: 2; }
      #main-header .navbar .logo input[type='checkbox'][id='toggler']:checked + .hamburger > div {
        transform: rotate(135deg); }
      #main-header .navbar .logo input[type='checkbox'][id='toggler']:checked + .hamburger > div::before,
      #main-header .navbar .logo input[type='checkbox'][id='toggler']:checked + .hamburger > div::after {
        content: '';
        top: 0;
        transform: rotate(90deg); }
      #main-header .navbar .logo .hamburger {
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        margin-right: 0.5rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center; }
      #main-header .navbar .logo .hamburger > div {
        position: relative;
        background-color: white;
        width: 80%;
        height: 2px;
        padding: 0 0rem;
        transition: all 0.2s ease-in-out; }
      #main-header .navbar .logo .hamburger > div:before {
        content: '';
        position: absolute;
        background-color: white;
        top: -10px;
        width: 100%;
        height: 2px; }
      #main-header .navbar .logo .hamburger > div:after {
        content: '';
        position: absolute;
        background-color: white;
        top: 10px;
        width: 100%;
        height: 2px; }
    #main-header .navbar .nav {
      width: 100%; }
      #main-header .navbar .nav-hide {
        display: none; }
      #main-header .navbar .nav-show {
        animation: fadeIn 0.2s forwards ease-in; }
  @keyframes fadeIn {
    from {
      opacity: 0; }
    to {
      opacity: 1;
      display: block;
      background-color: black; } }
      #main-header .navbar .nav ul {
        display: flex;
        flex-flow: column nowrap;
        align-items: center; }
        #main-header .navbar .nav ul li {
          padding: 0.3rem 0; }
  #main-header .showcase .container div h1 {
    font-size: 2.4rem; }
  #main-header .showcase .container div .typewriter {
    font-size: 1.1rem; }
  main #about .container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    width: 100%;
    margin: 0; }
    main #about .container div:first-child {
      grid-column: 1 / span 1;
      text-align: center;
      align-self: center;
      padding-top: 2.2rem;
      justify-self: flex-end;
      font-family: 'Dosis', sans-serif;
      font-size: 1.5rem; }
    main #about .container div:nth-child(2) {
      margin: 1rem 0 1rem 0;
      max-width: 90%;
      min-width: 90%;
      text-align: justify;
      align-self: center; }
    main #about .container div:nth-child(3) {
      margin: 0 0 1rem 0;
      max-width: 90%;
      min-width: 90%;
      text-align: justify;
      align-self: center; }
    main #about .container div:last-child {
      margin: 0 0 0 0;
      width: 90%;
      text-align: justify;
      align-self: center; }
  main #projects .outer-container {
    margin-top: 4rem;
    padding: 0 1rem; }
    main #projects .outer-container .container {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      grid-gap: 2rem; }
      main #projects .outer-container .container .project {
        grid-column: span 3; }
      main #projects .outer-container .container #project-1 img {
        width: 100%; }
  main #contact {
    margin-top: 0; }
    main #contact .contact-inner {
      width: 100%; }
      main #contact .contact-inner > * {
        width: 90%; } }
